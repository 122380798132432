<template>
  <v-container>
    <v-row>
      <v-col
        class="d-flex-5"
        lg="10"
        md="12"
        sm="12"
      >
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="date ? dateFormatted : dateParent"
              :label="title"
              hint="DD/MM/YYYY"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              :rules="$rules.required"
              required
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="date"
            no-title
            @input="menu1 = false"
          />
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    dateParent: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    date: '',
    dateFormatted: '',
    menu1: false,
  }),

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.$emit('update-date', this.dateFormatted);
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
